// @flow

import appboy from "appboy-web-sdk";
import _ from "lodash";
// import Config from "@Config";
import moment from "moment";
import store, { history } from "@Store";
import { type ProfileType } from "@SharedTypes";
import handleDeeplink from "@utils/deeplinkHelper";
import Config from "@Config";
import { matchPath } from "react-router";

export const initAppboy = (key: string, baseUrl: string) => {
    // init appboy with key
    appboy.initialize(key, {
        baseUrl: baseUrl,
        minimumIntervalBetweenTriggerActionsInSeconds: 0
    });

    // handling of appboy/braze popup notififcations
    appboyHandleInAppMessageLinks();

    // init the session
    appboy.openSession();
};

export const appboyHandleInAppMessageLinks = () => {
    appboy.subscribeToNewInAppMessages(function(
        inAppMessages: Array<any>
    ): any {
        var message = inAppMessages[0];

        if (message && message instanceof appboy.InAppMessage) {
            var replaceClickAction = function(item: any, clickAction: any) {
                var targetUri = item.uri;
                item.clickAction = clickAction.NONE;
                item.subscribeToClickedEvent(function(e: any) {
                    // check whether uri is internal or external
                    const url = new URL(targetUri, window.location.origin);

                    const match = matchPath(url.pathname, {
                        path: Config.routes.deeplinks,
                        exact: true
                    });
                    if (match) {
                        handleDeeplink(targetUri);
                        return;
                    }

                    if (document.location.hostname.includes(url.hostname)) {
                        // push internal uri to react-router-dom (html5 history)
                        history.push(targetUri);
                    } else {
                        // handle normal url redirect
                        window.location = targetUri;
                    }
                });
            };

            if (message.clickAction === appboy.InAppMessage.ClickAction.URI) {
                replaceClickAction(message, appboy.InAppMessage.ClickAction);
            }

            for (var i = 0; i < message.buttons.length; i++) {
                if (
                    message.buttons[i].clickAction ===
                    appboy.InAppMessage.ClickAction.URI
                ) {
                    replaceClickAction(
                        message.buttons[i],
                        appboy.InAppMessage.ClickAction
                    );
                }
            }
        }

        appboy.display.showInAppMessage(message);
        return inAppMessages.slice(1);
    });
};

export const appboyLoadFeed = (
    user: ProfileType,
    containerNode: any,
    allowedCategories: any
) => {
    if (appboy && containerNode) {
        try {
            appboy.subscribeToFeedUpdates(feed => {
                var cards = feed.cards;

                try {
                    cards[0].extras.hideOn = "web";
                    _.remove(cards, (card: any): boolean => {
                        return card.extras.hideOn === "web";
                    });
                    appboy.display.showFeed(
                        containerNode,
                        cards,
                        allowedCategories
                    );
                } catch {}
            });
            appboy.requestFeedRefresh();
        } catch (err) {}
    }
};

export const appboySetCustomAttribute = (name: string, value: any) => {
    const state = store.getState();
    if (state.user.appboy_user_id && !state.user.is_guest_user) {
        appboy.getUser().setCustomUserAttribute(name, value);
    }
};

export const appboyLogCustomEvent = (value: string) => {
    const state = store.getState();
    if (state.user.appboy_user_id && !state.user.is_guest_user) {
        appboy.logCustomEvent(value);
    }
};

export const appboyLogPurchase = (productId: string, price: number, currencyCode?: undefined | string, quantity?: undefined | number, purchaseProperties?: undefined | object) => {
    const state = store.getState();
    if (state.user.appboy_user_id && !state.user.is_guest_user) {
        appboy.logPurchase(productId, price, currencyCode, quantity, purchaseProperties);
    }
}

export const logAddToCart = (item: any) => {
    // appboy.logPurchase(
    //     `ADD-${item.promo_id || item.pos_item_id}`,
    //     "0",
    //     "AUD",
    //     item.quantity,
    //     { productID: item.promo_id || item.pos_item_id }
    // );
    const state = store.getState();
    if (state.user.appboy_user_id && !state.user.is_guest_user) {
        appboy.logCustomEvent("AddtoCart", {
            channel: "web",
            storeID: state.menu.store_id,
            deliveryMethod: "Delivery",
            deliveryTime:
                state.session.deliveryTime === "asap" ? "ASAP" : "ForLater",
            productID: item.promo_id || item.pos_item_id,
            comboLevel: item.combo_level,
            quantity: item.quantity,
            price: item.price
        });
    }
};

export const logCustomEventWithDelivery = (
    eventName: string,
    properties: any = {}
) => {
    const state = store.getState();
    if (state.user.appboy_user_id && !state.user.is_guest_user) {
        appboy.logCustomEvent(eventName, {
            channel: "web",
            storeID: state.menu.store_id,
            deliveryMethod: "Delivery",
            deliveryTime:
                state.session.deliveryTime === "asap" ? "ASAP" : "ForLater",
            // paymentMethod: "CC",
            ...properties
        });
    }
};

//
// Update appboy user with user profile object
//
export const appboyUpdateUser = (user: ProfileType, register?: boolean) => {
    if (user && user.appboy_user_id) {
        // set current user
        appboy.changeUser(user.appboy_user_id);

        // set first name
        appboy.getUser().setFirstName(user.first_name);

        // set last name
        appboy.getUser().setLastName(user.last_name);

        // set email
        appboy
            .getUser()
            .setEmail(user.email || user.unverified_email || user.guest_email);

        // set birthday
        if (user.birth_date) {
            const dob = moment(user.birth_date);
            const day = dob.format("D");
            const month = dob.format("M");
            const year = dob.format("Y");
            appboy.getUser().setDateOfBirth(year, month, day);
        }

        // set gender
        if (user.gender === "Male") {
            appboy.getUser().setGender(appboy.User.Genders.MALE, () => {});
        } else if (user.gender === "Female") {
            appboy.getUser().setGender(appboy.User.Genders.FEMALE, () => {});
        }

        // set phone
        if (user.phone) {
            appboy.getUser().setPhoneNumber(user.phone.replace(/^04/, "+614"));
        }

        // set postcode
        if (user.postcode) {
            appboySetCustomAttribute("Postcode", user.postcode);
        }

        // set verified flag
        appboySetCustomAttribute("IsEmailVerified", user.is_email_verified);

        // set profile completion percent
        appboySetCustomAttribute(
            "ProfilePercentage",
            user.profile_percent_complete
        );

        // set email news preference
        appboySetCustomAttribute(
            "CategoryPreference_EmailNews",
            _.get(user.notifications, "email.news") || false
        );

        // set email vouchers preference
        appboySetCustomAttribute(
            "CategoryPreference_EmailVouchers",
            _.get(user.notifications, "email.voucher") || false
        );

        // set email vouchers preference
        appboySetCustomAttribute(
            "CategoryPreference_SMS_News_Vouchers",
            _.get(user.notifications, "sms") || false
        );

        // set on screen vouchers preference
        appboySetCustomAttribute(
            "CategoryPreference_PushVouchers",
            _.get(user.notifications, "on_screen.voucher") || false
        );

        // set on  screen news preference
        appboySetCustomAttribute(
            "CategoryPreference_PushNews",
            _.get(user.notifications, "on_screen.news") || false
        );

        // set loyalty card number
        appboySetCustomAttribute("LoyaltyCard", user.loyalty_card_no || false);

        if (user.created) {
            // set registration date
            appboySetCustomAttribute(
                "RegistrationTime",
                new Date(user.created)
            );
            // set terms and conditions accepted date
            appboySetCustomAttribute("TcsAccepted", new Date(user.created));
        }

        if (register && !_.isNull(user.phone)) {
            // set SMS subscription group for both one-way and two-way
            appboy.getUser().addToSubscriptionGroup("6af67633-d317-4827-be7b-a2158db4ba45")
            appboy.getUser().addToSubscriptionGroup("1127e7c9-617f-4adf-be76-2c840e790ac4")
        }
    }
};

export const brazeUpdateCoffeeLoyalty = (props: any) => {
    !_.isNil(props.BPLifetimeFreeCoffee) &&
        appboySetCustomAttribute("BPLifetimeFreeCoffee", props.BPLifetimeFreeCoffee);

    !_.isNil(props.CoffeeLoyaltyLastRedemptionDateUtc) &&
        appboySetCustomAttribute("CoffeeLoyaltyLastRedemptionDateUtc", props.CoffeeLoyaltyLastRedemptionDateUtc)
    
    !_.isNil(props.StampsCount) && 
        appboySetCustomAttribute("StampsCount", props.StampsCount);

    !_.isNil(props.HasCoffeeVoucherAssigned) &&    
        appboySetCustomAttribute("HasCoffeeVoucherAssigned", props.HasCoffeeVoucherAssigned);
    
    !_.isNil(props.CoffeeParticipation) &&
        appboySetCustomAttribute("CoffeeParticipation", props.CoffeeParticipation);
};

// Reset appboy/braze user session

export const appboyResetUser = () => {
    appboy.openSession();
    appboy.changeUser();
};
